<template>
  <el-card class="v_user_account_list">
    <div class="g-flex"  slot="header">
      <span>实名审核列表</span>
      <!-- <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="setForm()">新增黑名单</el-button>
      </div> -->
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <!-- <el-form-item label="平台类型">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="all"></el-option>
          <el-option label="淘宝/天猫" value="1"></el-option>
          <el-option label="京东" value="0"></el-option>
          <el-option label="拼多多" value="2"></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="审核状态">
        <el-select v-model="query.real_status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核通过" value="2"></el-option>
          <el-option label="审核未通过" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="手机号">
        <el-input v-model="query.mobile" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入手机号" clearable></el-input>
      </el-form-item>
      
      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="姓名" value="name"></el-option>
          <el-option label="用户ID" value="id"></el-option>
          <el-option label="微信" value="wechat"></el-option>
          <el-option label="QQ" value="qq"></el-option>
        </el-select>
        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info"  @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="手机号" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.mobile}}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户ID" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.id}}</span>
        </template>
      </el-table-column>
      <el-table-column label="真实姓名" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="身份证号"  :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.card_id }}</span>
        </template>
      </el-table-column>
        <el-table-column label="出生年月" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{formatDate(scope.row.birthday, 'yyyy-MM-dd')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属地区" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.region }}</span>
        </template>
      </el-table-column>
      <el-table-column label="详细地址" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.address }}</span>
        </template>
      </el-table-column>
      <el-table-column label="微信" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.wechat }}</span>
        </template>
      </el-table-column>

      <el-table-column label="QQ" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.qq }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" :show-overflow-tooltip="true" fixed="right">
          <template slot-scope="scope">
            <span v-if="scope.row.real_status==1" class="g-red">待审核</span>
            <span v-else-if="scope.row.real_status==2" class="g-green">审核通过</span>
            <span v-else-if="scope.row.real_status==3" class="g-red">审核不通过</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="setForm(scope.row, 1)">审核</el-button>
          <el-button size="mini" type="primary" @click="setForm(scope.row, 2)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <!--新增和编辑-->
    <el-dialog :close-on-press-escape="false" :title="seeStatus == 1 ? '实名审核': '查看详情'" top="20px" :close-on-click-modal="false" :visible.sync="form" width="800px">
        <el-form :model="formData" label-width="120px" size="mini">
            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="真实姓名:">
                  <span>{{formData.name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码:">
                  <span>{{formData.mobile}}</span>
                </el-form-item>
              </el-col>

            </el-row>

            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="用户昵称:">
                  <span>{{ formData.nick_name }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户头像:">
                  <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.head_img " :preview-src-list="[ formData.head_img ]"></el-image>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="身份证号码:">
                  <el-input v-model="formData.card_id" autocomplete="off" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别:">
                  <el-radio-group v-model="formData.sex" disabled>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="身份证正面照:">
                  <el-image fit="cover" style="width: 220px; height: 100px" :src="formData.card_front" :preview-src-list="[formData.card_front]"> </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证反面照:">
                  <el-image fit="cover" style="width: 220px; height: 100px" :src="formData.card_back" :preview-src-list="[formData.card_back]"> </el-image>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="出生年月:">
                  <span class="g-grey">{{formatDate(formData.birthday, 'yyyy-MM-dd')}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="所属省份:">
                  <span  class="g-grey">{{formData.region}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="详细地址:">
                  <span  class="g-grey">{{formData.address}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="QQ:">
                  <span>{{formData.qq}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="微信:">
                  <span>{{formData.wechat}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="24">
                <el-form-item label="状态:">
                  <span>{{ formData.real_status | filtersRealStatus }}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="18">
                <el-form-item label="审核不通过理由:">
                  <el-input v-model="formData.real_remark" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入审核不通过理由"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>

      <div slot="footer" class="dialog-footer">
          <el-button @click="form = false" v-show="seeStatus === 2">取 消</el-button>
          <el-button type="primary" @click="form = false" v-show="seeStatus === 2">确定</el-button>
          <el-button type="danger"  @click="apiRealUpStatusHandel(3)" v-show="seeStatus === 1">审核不通过</el-button>
          <el-button type="success" v-show="seeStatus === 1" @click="apiRealUpStatusHandel(2)">审核通过</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetReaList, apiRealUpStatus } from '@/utils/api.js'
  export default {
    name: 'userRealList',
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        seeStatus: 1, //1 审核通过 2审核不通过

        query: { search_key: 'name', search_val: '', mobile: '', page:1, limit: 15, real_status: 'ALL' },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: { remark: ''},

        options: [
          {
            value: 0,
            label: '长期屏蔽'
          },
          {
            value: 60 * 30,
            label: '屏蔽半小时'
          }, 
          {
            value: 60 * 60,
            label: '屏蔽一小时'
          }, 
          {
            value: 60 * 60 * 2,
            label: '屏蔽两小时'
          }, 
          {
            value: 60 * 60 * 6,
            label: '屏蔽六小时'
          }, 
          {
            value:  60 * 60 * 24,
            label: '屏蔽一天'
          }],
          
      }
    },
    created() {
      this.getList()
    },
    filters: {
      filtersRealStatus(status) {
        if(status == 1) return '待审核'
        if(status == 2) return '审核通过'
        if(status == 3) return '审核不通过'
      }
    },
    methods: {
      formatDate,
      // 实名审核
      async apiRealUpStatusHandel(status) {
        if(status == 3 && !this.formData.real_remark) return this.$message.error('请填写审核不通过理由')
        let loading = this.$loading()
        const { success, data } = await apiRealUpStatus(Object.assign({}, { id: this.formData.id, remark: this.formData.real_remark }, { status })).catch(() => { loading.close() })
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        this.form = false
        this.getList(false)
      },

      // 获取列表
      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetReaList(this.query)
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
        if(!success) return
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: '' }, status = 1) {
        this.formData = Object.assign({}, formData)
        this.seeStatus = status
        this.form = true
      },
      // 点击重置
      resetData() {
        this.query =  { search_key: 'name', search_val: '', mobile: '', page:1, limit: 15, real_status: 'ALL' },
        this.getList()
      },
    }
  }
</script>

<style lang="scss">
.v_user_account_list {
  .el-form {
      .el-form-item {
        &.area-form-item {
          .el-form-item__content {
            line-height: 1;
          }
        }
      }
  }
}

</style>